import React, {useState} from 'react';
import { Box, Grid, TextField, Button, Container, useMediaQuery, useTheme, Snackbar, Alert } from '@mui/material';
import yourImage from '../assets/sendEmail.png'; // replace with your actual image

function ContactForm() {
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({ email: "", phoneNumber: "" });
  const [formErrors, setFormErrors] = useState({ email: "", phoneNumber: "" });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const boxStyle = isSmallScreen ? { height: 'auto', alignItems: 'center'} : { height: '60vh', alignItems: 'center'};

  const handleInputChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    });
    setFormErrors({
      ...formErrors,
      [event.target.name]: ''
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // simple validation
    let emailError = "", phoneError = "";
    if (!formValues.email.includes("@")) {
      emailError = "Invalid email format";
    }
    if (isNaN(formValues.phoneNumber)) {
      phoneError = "Phone number should only contain numbers";
    }
    if(emailError || phoneError) {
      setFormErrors({ email: emailError, phoneNumber: phoneError });
      return;
    }

    const formData = new FormData(event.currentTarget);
    try {
      const response = await fetch("https://getform.io/f/544a868e-5966-457c-993c-683932bb09cf", {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        setOpen(true);
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  };

  return (
    <Box style={boxStyle}  id="kontakt"  >
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <img src={yourImage} alt="Your Image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField name="restaurantName" fullWidth label="Restaurant Navn" variant="filled" InputProps={{style: { backgroundColor: 'white'}}} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="email" type="email" fullWidth label="Din Email" variant="filled" InputProps={{style: { backgroundColor: 'white'}}} onChange={handleInputChange} error={Boolean(formErrors.email)} helperText={formErrors.email} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="phoneNumber" type="tel" fullWidth label="Telefon Nummer" variant="filled" InputProps={{style: { backgroundColor: 'white'}}} onChange={handleInputChange} error={Boolean(formErrors.phoneNumber)} helperText={formErrors.phoneNumber} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="restaurantAddress" fullWidth label="Restaurant Addresse" variant="filled" InputProps={{style: { backgroundColor: 'white'}}} />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="subject" fullWidth label="Emne" variant="filled" InputProps={{style: { backgroundColor: 'white'}}} />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="comment" fullWidth label="Kommentar" variant="filled" InputProps={{style: { backgroundColor: 'white'}}} multiline rows={4} />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    style={{
                      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                      color: 'white',
                      fontWeight: 'bold',
                      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                      marginTop: '20px',
                      padding: '15px 50px',
                      fontSize: '20px',
                      width: '100%',
                      borderRadius: '50px'
                    }}
                  >
                    Send
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
              <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
                <div style={{ fontSize: '20px' }}>Your form has been submitted successfully!</div>
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ContactForm;
