import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const ScrollDownContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '50px', // Adjust this value to change the height of the component
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: "2%",
  marginBottom: "5%",
  '@media (max-width: 600px)': {
    marginTop: "10%",
    marginBottom: "15%",
  },
});

const ArrowDown = styled('div')({
  position: 'absolute',
  bottom: '10px',
  width: '30px',
  height: '30px',
  borderTop: '3px solid #FB673D',
  borderRight: '3px solid #FB673D',
  transform: 'rotate(135deg)',
  animation: 'bounce 2s infinite',
  '@keyframes bounce': {
    '0%, 20%, 50%, 80%, 100%': {
      transform: 'translateY(0) rotate(135deg)',
    },
    '40%': {
      transform: 'translateY(-10px) rotate(135deg)',
    },
    '60%': {
      transform: 'translateY(-5px) rotate(135deg)',
    },
  },
});


function ScrollDownIndicator() {
  return (
    <ScrollDownContainer>
      <ArrowDown />
    </ScrollDownContainer>
  );
}

export default ScrollDownIndicator;
