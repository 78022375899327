import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Grid, Button, IconButton, useMediaQuery, Drawer, List, ListItem, ListItemIcon, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/take-away-systems-logo-orange.png';
import heroImg from '../assets/heroimg_auto_x2_2.jpg';
import ContactFormDialog from './ContactFormDialog.js';

function Hero() {
    const [open, setOpen] = useState(false);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme => theme.breakpoints.down('sm'));


    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
    };

    return (
        <Box>
            {/* Header/Navbar */}
            <AppBar position="fixed" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }} elevation={0}>
                <Container maxWidth="xl">
                    <Toolbar style={{paddingLeft: "0px"}}>
                        <img src={logo} alt="logo" style={{ width: isMobile ? 150 : 200, paddingTop: isMobile ? 10 : 32 }} />
                        {isMobile ? (
                            <>
                              <IconButton onClick={handleDrawerToggle} style={{position:"absolute", right:"10px"}} color="inherit">
                                <MenuIcon style={{ color: '#FB673D', fontSize: 50 }} />
                              </IconButton>


                                <Drawer anchor="left" open={drawerOpen} onClose={closeDrawer}>
                                    <List>
                                        <ListItem button onClick={closeDrawer}>
                                            <ListItemIcon>
                                                <Typography component="a" href="#services" style={{
                                                    fontFamily: 'madefor-display-bold, helveticaneuew01-65medi, helveticaneuew02-65medi, helveticaneuew10-65medi, sans-serif',
                                                    fontSize: '35px',
                                                    color: '#FB673D',
                                                    textAlign: "left",
                                                    fontWeight: 'bold',
                                                    marginTop: '20px'}} >Services</Typography>
                                            </ListItemIcon>
                                        </ListItem>
                                        <ListItem button onClick={closeDrawer}>
                                            <ListItemIcon>
                                                <Typography component="a" href="#kontakt" style={{
                                                    fontFamily: 'madefor-display-bold, helveticaneuew01-65medi, helveticaneuew02-65medi, helveticaneuew10-65medi, sans-serif',
                                                    fontSize: '35px',
                                                    color: '#FB673D',
                                                    textAlign: "left",
                                                    fontWeight: 'bold',
                                                    marginTop: '20px'}} >Kontakt</Typography>
                                            </ListItemIcon>
                                        </ListItem>
                                        <ListItem button onClick={closeDrawer}>
                                            <ListItemIcon>
                                                <Typography component="a" href="https://dashboard.takeawaysys.com" style={{
                                                    fontFamily: 'madefor-display-bold, helveticaneuew01-65medi, helveticaneuew02-65medi, helveticaneuew10-65medi, sans-serif',
                                                    fontSize: '35px',
                                                    color: '#FB673D',
                                                    textAlign: "left",
                                                    fontWeight: 'bold',
                                                    marginTop: '20px'}} >Dashboard</Typography>
                                            </ListItemIcon>
                                        </ListItem>
                                    </List>
                                </Drawer>
                            </>
                        ) : (
                            <Box sx={{ ml: 'auto' }} >
                                <Button component="a" href="#services" color="inherit" style={{
                                    fontFamily: 'madefor-display-bold, helveticaneuew01-65medi, helveticaneuew02-65medi, helveticaneuew10-65medi, sans-serif',
                                    fontSize: '20px',
                                    color: '#FB673D',
                                    textAlign: "left",
                                    fontWeight: 'bold',
                                }} >Services</Button>
                                <Button component="a" href="#kontakt" color="inherit" style={{
                                    fontFamily: 'madefor-display-bold, helveticaneuew01-65medi, helveticaneuew02-65medi, helveticaneuew10-65medi, sans-serif',
                                    fontSize: '20px',
                                    color: '#FB673D',
                                    textAlign: "left",
                                    fontWeight: 'bold',
                                    marginLeft: "100px",
                                }} >Kontakt</Button>
                                <Button component="a" href="https://dashboard.takeawaysys.com" color="inherit" style={{
                                    fontFamily: 'madefor-display-bold, helveticaneuew01-65medi, helveticaneuew02-65medi, helveticaneuew10-65medi, sans-serif',
                                    fontSize: '20px',
                                    color: '#FB673D',
                                    textAlign: "left",
                                    fontWeight: 'bold',
                                    marginLeft: "100px",
                                }} >Dashboard</Button>
                            </Box>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
            <Toolbar style={{ minHeight: 110 }} /> {/* set the height to match the height of the AppBar */}

            {/* Content */}
            <Container maxWidth="xl">
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={4} order={{ xs: 2, md: 1 }}>
                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                            <Typography variant="h2" style={{
                                fontFamily: 'madefor-display-bold, helveticaneuew01-65medi, helveticaneuew02-65medi, helveticaneuew10-65medi, sans-serif',
                                fontSize: isTablet ? '35px' : '51px',
                                color: '#FB673D',
                                textAlign: "left",
                                fontWeight: 'bold',
                                marginTop: '20px',
                            }}>Online bestillingssystem til takeaway!</Typography>
                            <Typography variant="body1" style={{
                                fontFamily: 'madefor-display-bold, helveticaneuew01-65medi, helveticaneuew02-65medi, helveticaneuew10-65medi, sans-serif',
                                fontSize: isTablet ? '18px' : '20px',
                                color: '#FB673D',
                                textAlign: "left",
                                fontWeight: 'bold',
                                marginTop: '40px',
                                marginBottom: '30px',
                            }}>Dit takeaway-system der kan hjælpe dig med at øge dine salg!</Typography>
                            <Button variant="contained" style={{
                                backgroundColor: '#FB673D',
                                color: 'white',
                                fontFamily: 'madefor-display-bold, helveticaneuew01-65medi, helveticaneuew02-65medi, helveticaneuew10-65medi, sans-serif',
                                fontSize: isTablet ? '20px' : '20px',
                                padding: '10px 30px',
                                marginTop: '20px',
                                borderRadius: "20px",
                                fontWeight: isTablet ? 'bold' : 'inherit',
                                alignSelf: isTablet ? 'center' : 'inherit',
                            }}
                            onClick={() => setOpen(true)}
                            >Kom igang</Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={8} order={{ xs: 1, md: 2 }}>
                        <img src={heroImg} alt="description" style={{ width: '100%', height: 'auto' }} />
                    </Grid>
                </Grid>
            </Container>

            <ContactFormDialog open={open} setOpen={setOpen} />
        </Box>
    );
}

export default Hero;
