import logo from './logo.svg';
import './App.css';

import Hero from './components/Hero';
import ScrollDownIndicator from './components/ScrollDownIndicator';
import ServiceSection from './components/ServiceSection';
import ParallaxImage from './components/ParallaxImage';
import AlternatingImageText from './components/AlternatingImageText';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Hero />
      <ScrollDownIndicator />
      <ServiceSection />
      <ParallaxImage />
      <AlternatingImageText />
      <ContactForm  />
      <Footer />
    </div>
  );
}

export default App;
