import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const Footer = () => {
    return (
        <Box sx={{ bgcolor: '#607980', color: '#fff', mt: 5, py: 3 }}>
            <Container maxWidth="lg">
                <Typography variant="body1" align="center">
                    © {new Date().getFullYear()} Take Away Systems. All rights reserved.
                </Typography>
                <Typography variant="body2" align="center">
                    Privacy Policy | Terms of Service
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;
