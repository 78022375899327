import React from 'react';
import { Box, Grid, Container, Typography } from '@mui/material';
import ServiceCard from './ServiceCard';
import onlineOrderingSysImg from '../assets/OnlineOrderingSys.png';
import marketnigImg from '../assets/marketing.png';
import dashboardImg from '../assets/dashboard.png';
import posSys from '../assets/POSSys.png';


// Replace these with the actual images you want to use
const image1 = onlineOrderingSysImg;
const image2 = marketnigImg;
const image3 = dashboardImg;
const image4 = posSys;

const services = [
  {
    title: 'Online Take Away',
    description: 'Gør din restaurantoplevelse digital med vores Online Take Away-system. Vi designer brugervenlige hjemmesider, der vil forbedre dine kunders oplevelse og øge dine salg.',
    image: image1,
    price: "499 kr"
  },
  {
    title: 'Marketing Systems',
    description: 'Få maksimal synlighed online med vores Marketing Systems. Vi tilbyder SEO-optimering og avanceret brugeradfærd analyse for at øge din kundekreds og maksimere dit salg.',
    image: image2,
    price: "1999 kr"
  },
  {
    title: 'Dashboard',
    description: 'Få fuldstændig kontrol og indsigt i din forretning med vores Dashboard. Vi leverer realtidsstatistik og metrik, så du kan træffe informerede beslutninger og drive din restaurant effektivt.',
    image: image3,
    price: "GRATIS"
  },
  {
    title: 'Point of Sales system',
    description: 'Optimer din kundeservice med vores skræddersyede Point of Sales-system. Vores system er designet til at gøre interaktionen med dine kunder så glat og brugervenlig som muligt.',
    image: image4,
    price: "GRATIS"
  },
];


function ServicesSection() {
  return (
    <Container maxWidth="xl" style={{marginBottom: "5%"}} id="services">

      <Typography variant="h3" component="h2" style={{color: "#607980"}} >Vores Services</Typography>
      <Typography variant="subtitle1" style={{color: "#607980", fontSize: '24px'}} >
        Vi tilbyder en række kvalitetsservices designet til at hjælpe din restaurant med at trives.
      </Typography>
      <Box my={1}>
        <Grid container spacing={1}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <ServiceCard
                image={service.image}
                title={service.title}
                description={service.description}
                price={service.price} // Pass the index (incremented by 1) to ServiceCard
                index={index}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

export default ServicesSection;
