import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, useTheme, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContactForm from './ContactForm';

const ContactFormDialog = ({ open, setOpen }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={() => {setOpen(false)}}
            aria-labelledby="responsive-dialog-title"
            maxWidth="md"
        >
            <DialogTitle id="responsive-dialog-title">
                <IconButton edge="end" color="inherit" onClick={() => {setOpen(false)}} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers maxWidth="md"> 
                {"Kontakt os"}
                <ContactForm />
            </DialogContent>
        </Dialog>
    );
};

export default ContactFormDialog;
