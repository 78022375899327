import React from 'react';
import { Parallax } from 'react-parallax';
import { Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import theImage from '../assets/pizzaManSection_auto_x2.jpg';

const ParallaxImage = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const imageStyle = isSmallScreen ? { width: '320%', left: "68%" } : {};

    return (
        <Parallax bgImage={theImage} strength={500} bgImageStyle={imageStyle}>
            <Box style={{ height: '50vh' }}>
                <Box position="absolute" top="40%" left="50%" style={{transform: 'translate(-50%, -50%)'}}>
                </Box>
            </Box>
        </Parallax>
    );
};

export default ParallaxImage;
