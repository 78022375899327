import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

/*const ImageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  width: '300px',  // Fixed width
  height: '400px',  // Fixed height
  //border: '1px solid black',  // Added for visibility, remove if not needed

  '&:hover .service-text': {
    color: 'red',
  },
  '&:hover .service-icon': {
    opacity: 0.5,
  },
  '&:hover .text-color': {
    color: '#8B50CA',
    textShadow: 'none',
  },
}));*/

const ServiceIcon = styled('img')({
  width: '50%',
  height: 'auto',
  marginBottom: '20px',
  transition: 'opacity 0.3s',
});

const ServiceText = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  transition: 'color 0.3s',
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: '#607980',
  fontWeight: 'bold',
  fontSize: '28px',
  transition: 'color 0.3s, text-shadow 0.3s',
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
  color: '#607980',
  transition: 'color 0.3s, text-shadow 0.3s',
  fontSize: '17px',
  fontWeight: 'bold'
}));

function ServiceCard({ image, title, description, price, index }) {


  // Inside your component
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const ImageWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: matches ? '100%' : '300px',  // Conditional width based on screen size
    height: '400px',  // Fixed height
    //border: '1px solid black',  // Added for visibility, remove if not needed

    '&:hover .service-text': {
      color: 'red',
    },
    '&:hover .service-icon': {
      opacity: 0.5,
    },
    '&:hover .text-color': {
      color: '#8B50CA',
      textShadow: 'none',
    },
  });

  const handleClick = () => {
    console.log("clicked on service");
  };

  return (
    <ImageWrapper onClick={handleClick} >
      <ServiceIcon className="service-icon" src={image} alt={title} />
      <ServiceText className="service-text">
        <StyledTitle className="text-color" variant="h5">{title}</StyledTitle>
        <StyledDescription className="text-color" variant="body1">{description}</StyledDescription>
      </ServiceText>
    </ImageWrapper>
  );
}

export default ServiceCard;
