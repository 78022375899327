import React from 'react';
import { Box, Grid, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import Image1 from '../assets/whatever.png';
import Image2 from '../assets/growth.png';
import Image3 from '../assets/businessModel.png';

const AlternatingImageText = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const containerStyle = isSmallScreen ? { marginTop: "5%", marginBottom: "5%" } : { height: '40vh' };
  const itemStyle = isSmallScreen ? {} : { height: '40vh' };


  return (
    <Container maxWidth="lg">
      <Grid container>

        <Grid container item xs={12} style={containerStyle}>
          <Grid item order={{ xs: 2, md: 1 }} xs={12} md={6} style={{ height: '40vh' }} display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
            <Box p={2} style={{textAlign: "right"}} >
              <Typography variant="h4" component="h2" style={{color: "#607980"}} >"Vi er din digitale afdeling"</Typography>
              <Typography variant="subtitle1" style={{color: "#607980", fontSize: '20px'}} >
                Med Take Away Systems bliver din restaurant et digitalt powerhouse. Vi optimerer din online tilstedeværelse, øger trafikken og booster dit salg. Tænk på os som din egen softwareafdeling, der hjælper din restaurant med at vokse i den digitale verden.
              </Typography>
            </Box>
          </Grid>
          <Grid item order={{ xs: 1, md: 2 }} xs={12} md={6} style={{ height: '40vh' }} display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
            <img src={Image1} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="First Image"/>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={containerStyle}>
          <Grid item  xs={12} md={6} style={{ height: '40vh' }} display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
              <img src={Image2} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="Second Image"/>
          </Grid>
          <Grid item  xs={12} md={6} style={{ height: '40vh' }} display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
            <Box p={2} style={{textAlign: "left"}} >
              <Typography variant="h4" component="h2" style={{color: "#607980"}} >“Få flere stamkunder som er mere loyale til din restaurant”</Typography>
              <Typography variant="subtitle1" style={{color: "#607980", fontSize: '20px'}} >
                Research viser at 90% af take away restauranters kunder er stamkunder.

Vi har derfor lavet systemer som sørger for at du får flere stamkunder og at de vender til bage til præcis din restaurant med højre grad.

Derfor opnår vi de samme resultater som de dyre platforme, mens vi er meget billigere og meget mere effektive.
              </Typography>
            </Box>
          </Grid>
        </Grid>


        <Grid container item xs={12} style={containerStyle}>
          <Grid item order={{ xs: 2, md: 1 }} xs={12} md={6} style={{ height: '40vh' }} display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
            <Box p={2} style={{textAlign: "right"}} >
              <Typography variant="h4" component="h2" style={{color: "#607980"}} >"Din Succes er Vores Succes"</Typography>
              <Typography variant="subtitle1" style={{color: "#607980", fontSize: '20px'}} >
                Vores filosofi er at vores forretnings-model skal være i symbiose med vores kunders virksomhed. Derfor er det i vores interesse at hjælpe dig med at sælge mere og få flere kunder.

Derfor er vi et bedre alternativ til alle de andre dyre platforme.
              </Typography>
            </Box>
          </Grid>
          <Grid item order={{ xs: 1, md: 2 }} xs={12} md={6} style={{ height: '40vh' }} display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
            <img src={Image3} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="Third Image"/>
          </Grid>
        </Grid>


      </Grid>
    </Container>
  );
};

export default AlternatingImageText;
